import { useTranslate } from '@tolgee/react'
import { useNavigate } from 'react-router-dom'
import { ChatBubbleOutline, Favorite, FavoriteBorder, Info } from '@mui/icons-material'

export default function LibraryItem({ item, handleLikeChatbot }) {
  const navigate = useNavigate()
  const { t } = useTranslate()

  return (
    <div className='relative flex  h-[200px] w-[190px] min-w-[200px] cursor-pointer flex-col justify-between rounded-[10px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] transition hover:scale-[1.03] md:h-[230px] md:w-[210px] md:rounded-[20px] xl:h-[250px] xl:w-[230px]'>
      <div
        onClick={() => navigate(`/chatbot/chat/${item.name}`)}
        className='max-h-full flex-1 overflow-hidden p-4 md:w-auto md:p-5 md:text-center xl:p-[30px]'
      >
        <div className='mb-2 flex items-start gap-[6px] text-[15px] font-semibold leading-[1.2em] md:block md:text-[16px] xl:text-[18px]'>
          {item.image && <img src={item.image} alt='' className='mx-auto size-[80px]' />}
          <h2>{item.displayName ?? item.name}</h2>

          {item.longDescription && (
            <div
              onClick={(e) => e.stopPropagation()}
              data-tooltip-id={`eleo-tooltip`}
              data-tooltip-keyname={item.longDescription}
              className='right-4 top-4 -m-2 hidden p-2 md:absolute md:block'
            >
              <Info fontSize='small' className='text-brand-charcoal opacity-20 ' />
            </div>
          )}
        </div>
        <div className='text-balance text-[12px] leading-[1.5em] md:text-[14px] xl:text-[15px]'>
          {item.shortDescription}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/chatbot/library/${item.author}`)
          }}
          className='text-balance text-[12px]'
        >
          {item.author}
        </div>
        {item.category && (
          <span className='inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
            {t(item.category)}
          </span>
        )}
      </div>
      <div className='absolute bottom-0 flex h-10 w-full rounded-b-[10px] border-t bg-white'>
        <div
          onClick={() => navigate(`/chatbot/chat/${item.name}`)}
          className='flex flex-1 items-center justify-center gap-1 border-r'
        >
          <ChatBubbleOutline fontSize='inherit' />
          <span className='mb-[2px] text-[12px] font-semibold'>
            {t('eleo-chatbot-library-start-talking', 'Start talking')}
          </span>
        </div>
        <div
          onClick={() => handleLikeChatbot(item.id)}
          className='flex flex-1 items-center justify-center gap-1'
        >
          {item.isLiked ? <Favorite fontSize='inherit' /> : <FavoriteBorder fontSize='inherit' />}
          <span className='mb-[2px] text-[12px] font-semibold'>
            {t('eleo-chatbot-library-like', 'Like it')}
          </span>
        </div>
      </div>
    </div>
  )
}
