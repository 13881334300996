import { Animate } from 'components/lib'
import { useState } from 'react'
import { ChatbotLibraryLayout } from 'toolComponents/chat-bot/library'
import CategoriesSwitch from 'toolComponents/chat-bot/library/CategoriesSwitch'
import { useDebounce } from 'use-debounce'

export const ChatbotLibrary = () => {
  const [filter, setFilter] = useState('')
  const [debounceFilter] = useDebounce(filter, 500)

  return (
    <Animate>
      <CategoriesSwitch filter={filter} setFilter={setFilter} />
      <ChatbotLibraryLayout filter={debounceFilter} />
    </Animate>
  )
}

export default ChatbotLibrary
