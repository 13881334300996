import { Animate } from 'components/lib'
import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'
import { SectionLayout } from 'toolComponents/chat-bot/library/SectionLayout'
import { useTranslate } from '@tolgee/react'
import { useDebounce } from 'use-debounce'
import { useSearchParams } from 'react-router-dom'

export const ChatbotLibraryNew = () => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const [filter, setFilter] = useState('')
  const [debounceFilter] = useDebounce(filter, 1000)
  const { t } = useTranslate()
  const params = useSearchParams()[0]

  const fetchItems = useCallback(async () => {
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library?page=1&pageSize=40&filter=${debounceFilter}${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [debounceFilter, params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const title = t('recently-added-chatbots-title', 'Recently added Chatbots')

  return (
    <Animate>
      <SectionLayout
        items={items}
        title={title}
        filter={filter}
        setFilter={setFilter}
        fetchItems={fetchItems}
      />
    </Animate>
  )
}

export default ChatbotLibraryNew
