import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import { useSearchParams } from 'react-router-dom'

export const Popular = ({ filter, signal, refetchData }) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const { t } = useTranslate()
  const params = useSearchParams()[0]

  const fetchItems = useCallback(async () => {
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/popular?page=1&pageSize=10&filter=${filter}&period=ever${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [filter, params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (!items.length) return

  const title = t('popular-chatbots-title', 'Popular Chatbots')

  return (
    <ChatbotsCarousel
      items={items}
      title={title}
      refetchData={refetchData}
      seeMoreUrl='/chatbot/library/popular'
    />
  )
}

export default Popular
