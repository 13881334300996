import { Animate } from 'components/lib'
import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext } from 'react'
import { SectionLayout } from 'toolComponents/chat-bot/library/SectionLayout'
import { useTranslate } from '@tolgee/react'
import { useSearchParams } from 'react-router-dom'

export const ChatbotLibraryLiked = () => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const { t } = useTranslate()
  const params = useSearchParams()[0]

  const fetchItems = useCallback(async () => {
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library/liked?page=1&pageSize=40${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  const title = t('liked-chatbots-title', 'Your liked Chatbots')

  return (
    <Animate>
      <SectionLayout items={items} title={title} fetchItems={fetchItems} />
    </Animate>
  )
}

export default ChatbotLibraryLiked
