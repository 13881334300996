import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { T } from '@tolgee/react'
import { Link } from 'react-router-dom'
import { Input } from 'ui'
import { useSearchParams } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CategoriesSwitch({ filter, setFilter }) {
  const { chatbotCategories } = useChatbotCategories()
  const [params, setParams] = useSearchParams()
  const category = params.get('category')

  return (
    <div className='mb-5 border-b border-gray-200 p-3'>
      <div className='justify-between sm:flex sm:items-baseline'>
        <div className='flex-1 sm:flex sm:items-baseline'>
          <h3
            className='cursor-pointer text-base font-semibold leading-6 text-gray-900'
            onClick={() =>
              setParams(
                (prev) => {
                  prev.delete('category')
                  return prev
                },
                { replace: true }
              )
            }
          >
            <T keyName='eleo-chatbot-library-categories-title'>Categories</T>
          </h3>
          <div className='mt-4 sm:ml-10 sm:mt-0'>
            <nav className='-mb-px flex space-x-8'>
              {chatbotCategories.map((chatbotCategory) => (
                <div
                  key={chatbotCategory.value}
                  aria-current={chatbotCategory.value === category ? 'page' : undefined}
                  className={classNames(
                    chatbotCategory.value === category
                      ? 'border-transparent text-indigo-600 sm:border-indigo-500'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                  )}
                  onClick={() => setParams({ category: chatbotCategory.value }, { replace: true })}
                >
                  {chatbotCategory.label}
                </div>
              ))}
            </nav>
          </div>
        </div>

        {setFilter && (
          <div>
            <Input value={filter} onChange={setFilter} />
          </div>
        )}
      </div>
    </div>
  )
}
