import { ArrowForward } from '@mui/icons-material'
import { T } from '@tolgee/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Microbutton } from 'ui'
import { Liked } from './sections/Liked'
import { Popular } from './sections/Popular'
import { RecentlyAdded } from './sections/RecentlyAdded'
import { RecentlyUsed } from './sections/RecentlyUsed'

export const ChatbotLibraryLayout = ({ filter }) => {
  const [signal, refetchData] = useState(new Date())
  const navigate = useNavigate()

  return (
    <div className='flex h-full flex-col gap-6 p-2 md:p-5'>
      <Liked filter={filter} signal={signal} refetchData={refetchData} />
      <Popular filter={filter} signal={signal} refetchData={refetchData} />
      <RecentlyAdded filter={filter} signal={signal} refetchData={refetchData} />
      <RecentlyUsed filter={filter} signal={signal} refetchData={refetchData} />
      <div className='bg-brand-form-accent mt-8 flex items-center justify-between rounded-lg px-8 py-16'>
        <div className='text-[24px] font-extrabold leading-[1.6rem]'>
          <T
            keyName='eleo-chatbot-cta'
            params={{ br: <br></br> }}
            defaultValue='Ready to dive in? <br></br>Make your own chatbot today'
          />
        </div>

        <div className='flex gap-2'>
          <Microbutton
            text={<T keyName='eleo-start-today-action'>Start today</T>}
            className='!p-2'
            onClick={() => navigate('/account/chat-bot')}
          />
          <Microbutton
            variant='plain'
            text={<T keyName='eleo-learn-more'>Learn more</T>}
            icon={<ArrowForward fontSize='inherit' />}
            iconPlacement='right'
            iconSize={14}
          />
        </div>
      </div>
      <div className='pb-8' />
    </div>
  )
}
