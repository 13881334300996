import {
  ChevronLeft,
  ChevronRight,
  Close,
  Search,
  SearchOff,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import { Popover } from '@mui/material'
import { useTranslate, T } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useLanguages } from 'components/hooks/language'
import aiController from 'helpers/AIController'
import { useEffect, useState } from 'react'
import { ConversationContext, LanguageSelector, Microbutton, ModelSelector } from 'ui'

export function OptionsBar({
  state,
  dispatch,
  documentContext,
  handleClearContext,
  width,
  isHistoryVisible,
  audioStream,
  setAudioStream,
  setTtsIndex,
}) {
  const { t } = useTranslate()
  const { languages } = useLanguages()
  const [anchorEl, setAnchorEl] = useState(null)
  const [historyPanelWidth, setHistoryPanelWidth] = useState(
    Number(localStorage.getItem(`chat-panel-width`))
  )

  useEffect(() => {
    function listener(e) {
      setHistoryPanelWidth(e.detail)
    }
    window.addEventListener('onPanelResize', listener)
    return () => window.removeEventListener('onPanelResize', listener)
  }, [])

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'language-popover' : undefined

  // TODO also take into account if history panel is expanded
  const isCompactLayout = width < (isHistoryVisible ? 1220 + historyPanelWidth : 1060)

  //monitor and save model & language changes per user in DB
  const handleSaveUserSelectedSettings = async (language, model) => {
    const data = {
      language,
      model,
      toolName: 'chat',
    }

    await axios.post('/api/user/settings', data)

    //save values to localstorage to later sync it
    localStorage.setItem('settings', JSON.stringify(data))
  }

  if (isCompactLayout)
    return (
      <div className='xs:gap-y-2 xs:px-2 flex w-full max-w-[1000px] flex-wrap justify-between gap-x-1'>
        <div className='flex gap-[7px]'>
          <div className={classNames('flex gap-[7px]')}>
            {/* Audio switch */}
            <div id='audio-switch' className='bg-brand-violet/10 flex rounded-[4px]'>
              <Microbutton
                iconPlacement='left'
                variant='plain'
                icon={<VolumeOff fontSize='inherit' />}
                disabled={!state.isAutoAudio && !audioStream}
                onClick={() => {
                  dispatch({ type: 'SET_AUTO_AUDIO', payload: false })
                  setAudioStream()
                  setTtsIndex()
                }}
                className={classNames(
                  'rounded-r-none',
                  state.isAutoAudio && !audioStream
                    ? 'text-brand-violet/50 hover:text-brand-violet'
                    : 'text-brand-violet bg-brand-violet/10 '
                )}
              />
              <Microbutton
                iconPlacement='left'
                variant={state.isAutoAudio ? 'solid' : 'plain'}
                icon={<VolumeUp fontSize='inherit' />}
                disabled={state.isAutoAudio}
                onClick={() => dispatch({ type: 'SET_AUTO_AUDIO', payload: true })}
                className={classNames(
                  'rounded-l-none',
                  !state.isAutoAudio &&
                    'text-brand-violet/50 hover:bg-brand-violet/70 hover:text-white'
                )}
              />
            </div>

            {/* WWW switch */}
            <div className='bg-brand-violet/10 flex rounded-[4px]'>
              <Microbutton
                iconPlacement='left'
                variant='plain'
                icon={<SearchOff fontSize='inherit' />}
                disabled={!state.isSearchWeb}
                onClick={() => dispatch({ type: 'SET_SEARCH_WEB', payload: false })}
                className={classNames(
                  'rounded-r-none',
                  state.isSearchWeb
                    ? 'text-brand-violet/50 hover:text-brand-violet'
                    : 'text-brand-violet bg-brand-violet/10 '
                )}
              />
              <Microbutton
                iconPlacement='left'
                text='www'
                variant={state.isSearchWeb ? 'solid' : 'plain'}
                icon={<Search fontSize='inherit' />}
                disabled={state.isSearchWeb}
                onClick={() => dispatch({ type: 'SET_SEARCH_WEB', payload: true })}
                className={classNames(
                  'rounded-l-none',
                  !state.isSearchWeb &&
                    'text-brand-violet/50 hover:bg-brand-violet/70 hover:text-white'
                )}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-1 justify-end gap-[7px]'>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className='flex flex-col gap-2 p-2 py-3'>
              <ModelSelector
                id='model-selector'
                models={aiController.getSupportedTextModels(true)}
                model={state.model}
                handleModelChange={(model) => {
                  dispatch({ type: 'SET_MODEL', payload: model })
                  handleSaveUserSelectedSettings(state.language, model)
                }}
                translations={{
                  buttonLabel: t('eleo-chat-settings-model', 'Model'),
                  noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),

                  defaultModel: t('eleo-chat-settings-model-default', 'dafault'),

                  popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                  popupDescription: (
                    <T
                      keyName='eleo-chat-settings-model-popup-description'
                      params={{
                        a: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.techopedia.com/claude-vs-chatgpt'
                            className='text-brand-violet font-medium opacity-100'
                          />
                        ),
                        span: <span className='opacity-70' />,
                      }}
                      defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                    ></T>
                  ),
                }}
                callback={handleClose}
                className='!w-full'
              />

              <LanguageSelector
                label={t('eleo-chat-settings-language', 'Language')}
                language={state.language}
                languages={languages}
                onClick={(language) => {
                  dispatch({ type: 'SET_LANGUAGE', payload: language })
                  handleSaveUserSelectedSettings(language, state.model)
                }}
                translations={{
                  showAll: t('eleo-chat-settings-language-show-all', 'Show all'),
                  languageNotFound: t(
                    'eleo-chat-settings-language-not-found',
                    'Language not found'
                  ),
                  searchPlaceholder: t(
                    'eleo-chat-settings-language-search-placeholder',
                    'Find a language'
                  ),
                }}
                callback={handleClose}
              />

              <ConversationContext
                id='conversation-context'
                documentContext={documentContext}
                model={{ label: 'model', value: 'model' }}
                translations={{
                  buttonLabel: t('eleo-chat-settings-context-button-label', 'Context'),
                  storiesLabel: t('eleo-chat-settings-context-stories', 'Your stories'),
                  documentsLabel: t('eleo-context-modal-documents-title', 'Documents'),
                  websitesLabel: t('eleo-context-modal-websites-title', 'Websites'),
                  addContextLabel: t('eleo-chat-settings-context-add-context', 'Add context'),
                  noContextSelected: t('eleo-chat-settings-context-no-context-selected', 'None'),
                }}
                docOptions={['documents', 'stories']}
                callback={handleClose}
              />
            </div>
          </Popover>

          {/* Options button */}
          <Microbutton
            text={t('eleo-chat-options-settings', 'Settings')}
            variant='transparent'
            onClick={handleClick}
            className='h-[30px]'
          />
          {/* Clear chat */}
          <Microbutton
            title={t('eleo-chat-tooltip-clear-chat', 'Start a new conversation')}
            iconPlacement='left'
            text={t('eleo-chat-settings-clear-chat', 'Clear chat')}
            variant='transparent'
            icon={<Close fontSize='inherit' />}
            onClick={handleClearContext}
            className='h-[30px]'
          />
        </div>
      </div>
    )

  return (
    <div className='flex w-full max-w-[1000px] flex-wrap justify-between gap-y-2 sm:px-2'>
      <div className='flex gap-[7px]'>
        {/* Options switch */}
        <Microbutton
          title={
            state.isSettingsOpen
              ? t('eleo-chat-tooltip-hide-options', 'Hide options')
              : t('eleo-chat-tooltip-show-options', 'Show options')
          }
          iconPlacement='right'
          text={!state.isSettingsOpen && t('eleo-chat-options-settings', 'Settings')}
          icon={
            state.isSettingsOpen ? (
              <ChevronLeft fontSize='inherit' />
            ) : (
              <ChevronRight fontSize='inherit' />
            )
          }
          variant='transparent'
          onClick={() => dispatch({ type: 'TOGGLE_SHOW_SETTINGS' })}
          className='h-[30px]'
        />
        <div className='w-full overflow-clip'>
          <div
            className={classNames(
              'flex gap-[7px] transition-all duration-[500ms]',
              !state.isSettingsOpen && '-translate-x-full opacity-50'
            )}
          >
            <ModelSelector
              id='model-selector'
              title={t('eleo-chat-tooltip-models', 'Choose an AI model')}
              models={aiController.getSupportedTextModels(true)}
              model={state.model}
              handleModelChange={(model) => {
                dispatch({ type: 'SET_MODEL', payload: model })
                handleSaveUserSelectedSettings(state.language, model)
              }}
              translations={{
                buttonLabel: t('eleo-chat-settings-model', 'Model'),
                noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),

                defaultModel: t('eleo-chat-settings-model-default', 'dafault'),
                popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                popupDescription: (
                  <T
                    keyName='eleo-chat-settings-model-popup-description'
                    params={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://www.techopedia.com/claude-vs-chatgpt'
                          className='text-brand-violet font-medium opacity-100'
                        />
                      ),
                      span: <span className='opacity-70' />,
                    }}
                    defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                  ></T>
                ),
              }}
            />

            <LanguageSelector
              tooltipId={`eleo-tooltip`}
              tooltipKeyname={`eleo-chat-tooltip-language`}
              label={t('eleo-chat-settings-language', 'Language')}
              language={state.language}
              languages={languages}
              onClick={(language) => {
                dispatch({ type: 'SET_LANGUAGE', payload: language })
                handleSaveUserSelectedSettings(language, state.model)
              }}
              translations={{
                showAll: t('eleo-chat-settings-language-show-all', 'Show all'),
                languageNotFound: t('eleo-chat-settings-language-not-found', 'Language not found'),
                searchPlaceholder: t(
                  'eleo-chat-settings-language-search-placeholder',
                  'Find a language'
                ),
              }}
            />

            <ConversationContext
              id='conversation-context'
              tooltipId={`eleo-tooltip`}
              tooltipKeyname={`eleo-chat-tooltip-context`}
              documentContext={documentContext}
              model={{ label: 'model', value: 'model' }}
              translations={{
                buttonLabel: t('eleo-chat-settings-context-button-label', 'Context'),
                storiesLabel: t('eleo-chat-settings-context-stories', 'Your stories'),
                documentsLabel: t('eleo-context-modal-documents-title', 'Documents'),
                websitesLabel: t('eleo-context-modal-websites-title', 'Websites'),
                addContextLabel: t('eleo-chat-settings-context-add-context', 'Add context'),
                noContextSelected: t('eleo-chat-settings-context-no-context-selected', 'None'),
              }}
              docOptions={['documents', 'stories', 'websites']}
            />

            {/* Audio switch */}
            <div
              id='audio-switch'
              className='bg-brand-violet/10 flex rounded-[4px]'
              data-tooltip-id={`eleo-tooltip`}
              data-tooltip-keyname='eleo-chat-tooltip-speaker'
            >
              <Microbutton
                iconPlacement='left'
                variant='plain'
                icon={<VolumeOff fontSize='inherit' />}
                disabled={!state.isAutoAudio && !audioStream}
                onClick={() => {
                  dispatch({ type: 'SET_AUTO_AUDIO', payload: false })
                  setAudioStream()
                  setTtsIndex()
                }}
                className={classNames(
                  'rounded-r-none',
                  state.isAutoAudio && !audioStream
                    ? 'text-brand-violet/50 hover:text-brand-violet'
                    : 'text-brand-violet bg-brand-violet/10 '
                )}
              />
              <Microbutton
                iconPlacement='left'
                variant={state.isAutoAudio ? 'solid' : 'plain'}
                icon={<VolumeUp fontSize='inherit' />}
                disabled={state.isAutoAudio}
                onClick={() => dispatch({ type: 'SET_AUTO_AUDIO', payload: true })}
                className={classNames(
                  'rounded-l-none',
                  !state.isAutoAudio &&
                    'text-brand-violet/50 hover:bg-brand-violet/70 hover:text-white'
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-1 justify-end gap-[7px]'>
        {/* WWW switch */}
        <div
          className='bg-brand-violet/10 flex rounded-[4px]'
          data-tooltip-id={`eleo-tooltip`}
          data-tooltip-keyname={'eleo-chat-tooltip-www'}
        >
          <Microbutton
            iconPlacement='left'
            variant='plain'
            icon={<SearchOff fontSize='inherit' />}
            disabled={!state.isSearchWeb}
            onClick={() => dispatch({ type: 'SET_SEARCH_WEB', payload: false })}
            className={classNames(
              'rounded-r-none',
              state.isSearchWeb
                ? 'text-brand-violet/50 hover:text-brand-violet'
                : 'text-brand-violet bg-brand-violet/10 '
            )}
          />
          <Microbutton
            iconPlacement='left'
            text='www'
            variant={state.isSearchWeb ? 'solid' : 'plain'}
            icon={<Search fontSize='inherit' />}
            disabled={state.isSearchWeb}
            onClick={() => dispatch({ type: 'SET_SEARCH_WEB', payload: true })}
            className={classNames(
              'rounded-l-none',
              !state.isSearchWeb && 'text-brand-violet/50 hover:bg-brand-violet/70 hover:text-white'
            )}
          />
        </div>

        {/* Clear chat */}
        <Microbutton
          title={t('eleo-chat-tooltip-clear-chat', 'Start a new conversation')}
          iconPlacement='left'
          text={t('eleo-chat-settings-clear-chat', 'Clear chat')}
          variant='transparent'
          icon={<Close fontSize='inherit' />}
          onClick={handleClearContext}
          className='h-[30px]'
        />
      </div>
    </div>
  )
}
