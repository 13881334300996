import { useTranslate } from '@tolgee/react'
import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ChatbotsCarousel } from '../ChatbotsCarousel'
import { useSearchParams } from 'react-router-dom'

export const RecentlyAdded = ({ filter, signal, refetchData }) => {
  const context = useContext(ViewContext)
  const [items, setItems] = useState([])
  const { t } = useTranslate()
  const params = useSearchParams()[0]

  const fetchItems = useCallback(async () => {
    try {
      const category = params.get('category')
      const res = await axios.get(
        `/api/chat-bot/library?page=1&pageSize=10&filter=${filter}${category ? `&category=${category}` : ''}`
      )
      setItems(res.data)
    } catch (err) {
      context.handleError(err)
    }
  }, [filter, params])

  useEffect(() => {
    fetchItems()
  }, [fetchItems, signal])

  if (!items.length) return

  const title = t('recently-added-chatbots-title', 'Recently added Chatbots')

  return (
    <div className='flex flex-col gap-10 bg-slate-50'>
      <ChatbotsCarousel
        items={items}
        title={title}
        refetchData={refetchData}
        seeMoreUrl='/chatbot/library/new'
      />
    </div>
  )
}
